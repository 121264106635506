@mixin border-radius($v) {
  -webkit-border-radius: $v;
  -moz-border-radius: $v;
  border-radius: $v;
}
@mixin display($display: unset, $justify: unset, $align: unset, $text: unset) {
  display: $display;
  justify-content: $justify;
  align-items: $align;
  text-align: $text;
}
@mixin position(
  $position,
  $top: unset,
  $left: unset,
  $bottom: unset,
  $right: unset
) {
  position: $position;
  top: $top;
  left: $left;
  bottom: $bottom;
  right: $right;
}
@mixin box-shadow($props) {
  -webkit-box-shadow: $props;
  -moz-box-shadow: $props;
  box-shadow: $props;
}
@mixin transform($props) {
  -moz-transform: $props;
  -webkit-transform: $props;
  -o-transform: $props;
  -ms-transform: $props;
  transform: $props;
}
@mixin size($height, $width) {
  height: $height;
  width: $width;
}
@mixin sameSize($size) {
  height: $size;
  width: $size;
}
@mixin appearance {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
body {
  background-color: #f0f2f5;
}
ol,
ul {
  padding: 0px;
  list-style: none;
}
.ant-tabs {
  height: auto;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre {
  padding: 0;
  margin: 0 !important;
  font-weight: 600 !important;
}

* {
  font-weight: 600;
}

.video-wrapper {
  width: 640px;
  height: 360px;
  background: aliceblue;
}

.buttons-wrapper {
  display: flex;
  width: 640px;
  margin-top: 10px;
  justify-content: center;
}

.buttons-wrapper > button {
  margin: 5px;
}

.desc {
  span {
    background-color: transparent !important;
    color: #000 !important;
    font-size: 15px;
  }
}
.person-img {
  width: 10%;
  img {
    width: 100%;
    height: 100%;
  }
}

.about {
  padding-left: 20px;
}

.bg-white {
  background-color: white !important;
}
.w-100 {
  width: 100% !important;
}
.w-85 {
  width: 85% !important;
}
.h-100 {
  height: 100% !important;
}
.h-100vh {
  height: 100vh;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left !important;
}
.chart-div{
  width: 47%;
}
.row{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.row1{
  display: flex;
  align-items: start;
  justify-content: center;
  gap: 40px;
}
.text-uppercase {
  text-transform: uppercase;
}
.no-repeat {
  background-repeat: no-repeat;
  background-size: cover;
}
.fl {
  float: left;
}
.bold {
  font-weight: bold;
}

.flex {
  display: flex !important;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
}
.flex-100 {
  flex: 0 0 100%;
}

.flex.flex-between {
  -webkit-box-pack: space-between;
  -ms-flex-pack: space-between;
  justify-content: space-between;
}
.flex.flex-around {
  -webkit-box-pack: space-around;
  -ms-flex-pack: space-around;
  justify-content: space-around;
}
.flex.flex-end {
  justify-content: flex-end;
}
.flex.justify-center {
  justify-content: center;
}
.flex.flex-align-center {
  align-items: center;
}
.flex.flex-align-baseline {
  align-items: baseline;
}
.flex.all-center {
  justify-content: center !important;
  align-items: center !important;
}
.flex.dir-column {
  flex-direction: column;
}
.f-10 {
  font-size: 10px;
}
.f-11 {
  font-size: 11px;
}
.f-12 {
  font-size: 12px;
}
.f-13 {
  font-size: 13px;
}
.f-14 {
  font-size: 14px;
}
.f-15 {
  font-size: 15px;
}
.f-16 {
  font-size: 16px;
}
.f-17 {
  font-size: 17px;
}
.f-18 {
  font-size: 18px;
}
.f-19 {
  font-size: 19px;
}
.f-20 {
  font-size: 20px;
}
.f-21 {
  font-size: 21px;
}
.f-22 {
  font-size: 22px;
}
.f-23 {
  font-size: 23px;
}
.f-24 {
  font-size: 24px;
}
.f-25 {
  font-size: 25px;
}
.text-center {
  text-align: center;
}

.text-dark {
  color: black;
}
.text-uppercase {
  text-transform: uppercase;
}
.no-repeat {
  background-repeat: no-repeat;
  background-size: cover;
}
.p-0 {
  padding: 0px !important;
}
.p-1 {
  padding: 10px !important;
}
.p-5 {
  padding: 5px !important;
}

.p-15 {
  padding: 15px !important;
}

.p-3 {
  padding: 30px !important;
}
.pb-5 {
  padding-bottom: 5px !important;
}
.pb-10 {
  padding-bottom: 10px !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.mt-15 {
  margin-top: 15px !important;
}
.mt-5 {
  margin-top: 5px !important;
}
.pb-25 {
  padding-bottom: 25px !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pr-1 {
  padding-right: 10px !important;
}
.pr-2 {
  padding-right: 20px !important;
}
.pr-3 {
  padding-right: 30px !important;
}
.pr-4 {
  padding-right: 40px !important;
}

.pb-1 {
  padding-bottom: 10px !important;
}
.pl-1 {
  padding-left: 10px !important;
}
.px-2 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.pt-1 {
  padding-top: 10px !important;
}
.pt-15 {
  padding-top: 15px !important;
}
.pb-15 {
  padding-bottom: 15px !important;
}
.p-2 {
  padding: 20px !important;
}

.m-5 {
  margin: 5px;
}
.ml-5 {
  margin-left: 5px !important;
}
.ml-10 {
  margin-left: 10px !important;
}
.ml-15 {
  margin-left: 15px !important;
}
.ml-20 {
  margin-left: 20px !important;
}
.mr-5 {
  margin-right: 5px !important;
}
.mr-10 {
  margin-right: 10px !important;
}
.mr-15 {
  margin-right: 15px !important;
}
.mr-20 {
  margin-right: 20px !important;
}
.mr5-5 {
  margin-right: 5px !important;
}
.mr5-10 {
  margin-right: 10px !important;
}
.mr5-15 {
  margin-right: 15px !important;
}
.mr5-20 {
  margin-right: 20px !important;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.mb-5 {
  margin-bottom: 5px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mb-15 {
  margin-bottom: 15px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.mb-50 {
  margin-bottom: 50px !important;
}
.mb-65 {
  margin-bottom: 65px !important;
}
.mt-50 {
  margin-top: 50px !important;
}
.mt-65 {
  margin-top: 65px !important;
}
.mt-70 {
  margin-top: 70px !important;
}
.mt-75 {
  margin-top: 75px !important;
}
.mt-85 {
  margin-top: 85px !important;
}
.mt-0 {
  margin-top: 0px;
}
.mt-5 {
  margin-top: 5px !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.mt-15 {
  margin-top: 15px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-25 {
  margin-top: 25px !important;
}
.mt-30 {
  margin-top: 30px !important;
}
.mt-35 {
  margin-top: 35px !important;
}
m-10 {
  margin: 10px;
}
/* max width 90% */
.max-width-90 {
  max-width: 90%;
}

.startegic-select {
  width: 200px;
}

.edit .ant-upload-list-picture-card-container,
.edit .ant-upload.ant-upload-select-picture-card {
  width: 100%;
  height: 200px;
}
.register {
  position: relative;

  .ant-select-multiple .ant-select-selection-item {
    display: none;
  }
}
.ant-select-focused:not(.ant-select-disabled) .ant-select-selector {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}
.ant-table-column-sorters{
  padding: 0 !important;
}
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  object-fit: contain;
}
.ant-upload.ant-upload-select-picture-card {
  margin-bottom: 0;
}
.ant-select-selector {
  height: 100% !important;
}

textarea.ant-input {
  height: 300px !important;
}
.rejectNote {
  textarea.ant-input {
    height: 130px !important;
  }
}
.contact {
  display: flex;
}
.tableImage {
  width: 50px;
  height: 30px;
  object-fit: contain;
}
.green {
  color: #19be3e;
  font-size: 18px;
}

.position-absolute {
  position: absolute;
}
.position-relative {
  position: relative;
}

.border {
  border: 1px solid #0000001f !important;
}

.border-right {
  border-right: 1px solid #0000001f !important;
}

.border-left {
  border-left: 1px solid #0000001f !important;
}
.gap-4 {
  gap: 10px;
}
.border-bottom {
  border-bottom: 1px solid #0000001f !important;
}
.border-top {
  border-top: 1px solid #0000001f !important;
}
.border-none {
  border: none !important;
}
.num {
  width: 69px;
  height: 44px;
}
.w-full {
  width: 100%;
}
.error {
  color: red;
}
.errorInput {
  border-color: red;
}
.passForm {
  margin-bottom: 0px !important;
}
.ant-select-selection-placeholder {
  display: block;
}

/* loader */
.loaderFixed {
  position: fixed;
  width: 100%;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  top: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #1890ff;
  z-index: 99999;
}
.loader,
.loader:before,
.loader:after {
  background: #ffffff;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: #ffffff;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: "";
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
.seeSickModal {
  min-width: 44%;
}

.man-avatar {
  width: 25px !important;
  height: 25px !important;
}

.alt-menu-title {
  font-size: 13px;
}
.commontask{
  .ant-form-item{
    margin-bottom: 0;
  }
  &.detail{
    .ant-picker.ant-picker-disabled {
      background-color: #fff;
      color: #000;
      
    }
    .ant-picker-input > input[disabled] {
      color: #000;
    }
    .ant-select-disabled{
      .ant-select-selector{
      background-color: #fff;
      color: #000;
      }
    }
  }
}
.tr-flag{
  width: 20px;
  height: 20px;
}
.detail{
  .ant-picker.ant-picker-disabled {
    background-color: #fff!important;
    color: #000!important;
    
  }
  .ant-picker-input > input[disabled] {
    color: #000!important;
  }
  .ant-select-disabled{
    .ant-select-selector{
    background-color: #fff!important;
    color: #000!important;
    }
  }
}
/* Menu */
.sider-btn {
  padding: 52px 10px;
  &.open {
    flex-direction: row-reverse;
    align-items: center;
  }
  img {
    height: 95%;
    width: auto;
  }
}
.settings-alt-man {
  padding: 0 0 0 10px;
  display: flex;
  gap: 4px;
  align-items: center;
  font-weight: 500!important;
  cursor:default;
}
.top-menu {
  height: 75px;
  width: 100%;
  padding: 15px 20px;
  background: white;
  a {
    width: 100%;
    height: 100%;
  }
  img {
    @include size(95%, auto);
  }
  .ant-avatar {
    img {
      @include size(100%, auto);
    }
  }
}
.name-button {
  font-size: 16px;
}
.bottom-menu {
  position: absolute;
  background: white;
  bottom: 0;
  justify-content: space-between;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  .noti {
    display: none;
  }
  //padding: 10px 10px 10px 24px;
 
  .settings-menu {
    display: flex;
    flex-direction: column;
    padding-left: 17px;
    padding-top: 15px;
  }
  .settings-alt {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  .other-bottom {
    display: flex;
    margin-top: 10px;
    align-items: center;
    justify-content: center;
    width: 100%;
    justify-content: space-between;
    padding-top: 10px;
    border-top: #0000001f solid 1px;
    padding: 12px 24px;
  }
  .top-menu-navigation {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    gap: 16px;
    .circle-notification {
      background: #51bfa4;
      transition: 0.3s ease-out;
      &:hover {
        background: #327867;
        transition: 0.3s ease-out;
      }
    }
  }
  .icons {
    @include display(flex, space-between, center);
    svg {
      font-size: 18px;
    }
  }
}
.ant-layout-sider {
  top: 75px;
}
.ant-layout-sider-collapsed {
  top: 75px;
  .bottom-menu {
    flex-direction: column;
    .name-button {
      display: none;
    }
    .noti {
      display: block;
    }
    .settings-menu {
      display: flex;
      flex-direction: column;
    }
    .other-bottom {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding-top: 17px;
      border-top: #0000001f solid 1px;
    }
    // padding: 10px;
    .top-menu-navigation {
      display: flex;
      margin-bottom: 15px;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 10px;
      .circle-notification {
        background: #51bfa4;
        transition: 0.3s ease-out;
        &:hover {
          background: #327867;
          transition: 0.3s ease-out;
        }
      }
    }
    .icons {
      margin-top: 9px;
      flex-direction: column;
    }
  }
}
.ant-collapse-content > .ant-collapse-content-box{
  padding: 0;
}
@media (min-width: 1200px) {
  .collapsedRight {
    padding-left: 80px;
  }
  .nonCollapsedRight {
    padding-left: 300px;
  }
  .letside {
    padding-bottom: 20px;
    .side-menu {
      position: fixed !important;
      bottom: 0;
      height: 98vh;
      padding-bottom: 250px;
      margin-left: 10px;
      top: 0;
      z-index: 6;
      ul {
        &::-webkit-scrollbar,
        .ant-modal-body::-webkit-scrollbar {
          width: 0;
          display: none;
        }
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;
        direction: rtl;
        li {
          direction: ltr;
          text-align: inherit;
        }
      }
    }
  }
}
.collapsedRight,
.nonCollapsedRight {
  transition: all 0.2s;
  .page-routes {
    //padding-top: 90px;
  }
}
.top-menu {
  transition: all 0.2s;
  position: fixed;
  z-index: 5;
  right: 0;
  width: auto;
  top: 0;
}
.collapsedRight {
  .top-menu {
    left: 80px;
  }
}
.nonCollapsedRight {
  .top-menu {
    left: 300px;
  }
  .chart-pie {
    padding: 0 50px;
  }
}

@media (max-width: 1200px) {
  .top-menu {
    position: fixed;
    right: 0 !important;
    left: 0 !important;
  }
}
.drawer {
  ul {
    padding-bottom: 60px;
    height: 100%;
  }
}
//menu ends

//profile info page
.profil-info {
  min-width: 300px;
  width: auto;
  .ant-row {
    margin: 0px -16px;
    margin-top: -12px;
    padding-left: 16px;
  }
}

@media (max-width: 400px) {
  .profil-info {
    min-width: 250px;
  }
  .top-menu {
    padding: 15px;
    img {
      @include size(75%, auto);
    }
    .top-menu-navigation {
      right: 15px;
    }
  }
}

#page {
  display: flex;
  width: 100%;
  min-height: 100vh;
  font-weight: 600 !important;
}

.page-routes {
  padding: 20px;
  position: relative;
  max-width: 100%;
  height: 100%;
  &.isMap {
    padding: 0 !important;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media screen and (max-width: 991px) {
  //tabsection table responsive
  .ant-table-wrapper {
    background: transparent !important;
    .ant-pagination {
      margin: 0px !important;
      padding: 15px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
      background: white;
    }
    .ant-table {
      background: transparent !important;
      display: block;
      &-thead {
        display: none;
      }
      &-thead > tr,
      &-tbody > tr {
        background: white;
        border-radius: 2px;
        margin-bottom: 8px;
        display: block;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
        th,
        td {
          border-bottom: 1px solid #0000001f !important;
          padding: 10px 15px !important;
          font-size: 15px !important;
          display: flex !important;
          justify-content: flex-end !important;
          text-align: center;
        }
        > th,
        > td {
          display: block;
          width: auto !important;
          border: none;
          &:last-child {
            border-bottom: 1px solid #eee;
          }
        }
      }
    }
  }
  .areasTable {
    table {
      tr:not(.ant-table-expanded-row) {
        display: flex !important;
        flex-direction: column;
        td:nth-child(1) {
          order: 1 !important;
          font-size: 20px !important;
        }
      }
      .ant-table-expanded-row table tr td:nth-child(1) {
        order: unset !important;
        font-size: unset !important;
      }
    }
  }
}
@media (max-width: 420px) {
  .ant-table-wrapper {
    .ant-table {
      &-thead > tr,
      &-tbody > tr {
        th,
        td {
          padding: 10px !important;
          font-size: 13px !important;
        }
      }
    }
  }
}
.man {
  height: 100% !important;
}
.periods {
  background-color: #1890ff;
  width: 30px;
  height: 30px;
}
//full modal
.fullmodal {
  max-width: unset !important;
  margin: 0 !important;
  height: 100% !important;
  width: 100% !important;
  background: white;
  padding: 0 !important;
  .ant-modal-content {
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    .ant-modal-body {
      overflow-y: unset !important;
      padding: 0 !important;
      .modal-tabs {
        padding-top: 61px;
      }
    }
  }
  .ant-modal-close {
    display: none;
  }
  .ant-modal-header {
    padding: 0 !important;
    .custom-modal-header {
      background: #51bfa4;
      position: fixed;
      width: 100%;
      top: 0;
      z-index: 5;
      display: flex;
      color: white;
      padding-right: 20px;
      align-items: center;
      .addTask {
        margin-left: auto;
        background: #272727;
        cursor: pointer;
        padding: 20px;
        transition: 0.3s ease-out;
        &:hover {
          opacity: 0.8;
          transition: 0.3s ease-out;
        }
      }
      .heading {
        font-size: 20px;
      }
      .close {
        padding: 20px;
        cursor: pointer;
        transition: 0.3s ease-out;
        &:hover {
          opacity: 0.8;
          transition: 0.3s ease-out;
        }
      }
    }
  }
}

.addTaskModal {
  min-width: 85%;
}
.salaryHistoryModal {
  min-width: 70%;
}

//mediumMOdal
.mediumModal {
  min-width: 60%;
}
@media (max-width: 1200px) {
  .mediumModal {
    min-width: 70%;
  }
}
.ant-modal-content {
  .ant-modal-body {
    max-height: 486px;
    height: auto;
    overflow-y: auto;
    .ant-form-item {
      margin-bottom: 0 !important;
    }
    .formButton {
      margin-top: 35px;
      padding: 19px 5px;
      &.no-marg {
        margin-top: 0;
      }
    }
  }
}
.cropModal,
.demandModal {
  .ant-modal-close {
    display: none !important;
  }
}
.modalButtons.purchase {
  position: absolute;
  bottom: 23px;
  width: 100%;
  left: 0;
  padding: 10px 24px;
}

//statusbox
.statusBox {
  padding: 5px 10px;
  color: white;
  border-radius: 50px;
}
.statusTd {
  p {
    display: none;
  }
}
@media (max-width: 991px) {
  .statusTd {
    justify-content: space-between;
    align-items: center;
    width: 100%;
    div,
    span {
      text-align: right;
    }
    p {
      display: block;
      text-align: left;
    }
  }
}

//sidebar

// pageheading
.page-heading {
  justify-content: space-between;
}
@media (max-width: 767px) {
  .page-heading {
    justify-content: center;
    text-align: center;
    flex-direction: column;
    .page-name {
      margin-bottom: 10px;
    }
    .ant-btn {
      margin-bottom: 10px;
    }
  }
}
@media (max-width: 576px) {
  .page-heading {
    .page-name {
      &.small-name span {
        font-size: 16px !important;
      }
    }
  }
}

.map {
  height: 100%;
  width: 100%;
  position: absolute !important;
  width: 100%;
  height: 100%;
  right: 0;
  bottom: 0;
  left: 0 !important;
  width: auto !important;
  .gmnoprint .gm-style-mtc {
    top: 79px !important;
  }
}

.customtable2 {
  border: 1px solid #ddd;
  font-size: 14px;
  border-collapse: collapse;
  width: 100%;
  background-color: white;
  tr {
    border: 1px solid #ddd;
    td {
      padding: 10px;
      text-align: right;
      &:first-child {
        text-align: left;
      }
    }
  }
}

.padBottomButtons {
  position: "absolute";
  bottom: "20px";
  right: "40px";
}

.page {
  padding: 20px;
  background-color: white;
}
.left {
  float: left;
}

.red {
  color: red;
  font-size: 18px;
}

.table {
  border-collapse: collapse;
  margin-top: 60px;
  margin-bottom: 40px;
  th {
    border-bottom: 1px solid black;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    // color: #ffff;
    padding: 15px 0;
    width: 8%;
    &:nth-child(even) {
      background: rgba(255, 255, 255, 0.05);
    }
    &:first-child {
      width: 43%;
      text-align: left;
      padding-left: 10px;
    }
  }

  tr {
    border-bottom: 1px solid black;

    td {
      padding: 20px 10px;

      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: black;

      &:nth-child(even) {
        background: rgba(255, 255, 255, 0.05);
      }
    }
  }
  .statik p {
    width: 70%;
  }
}
.membersTable {
  display: flex;
  align-items: center;
  justify-content: center;
  .table {
    border-collapse: collapse;
    margin-top: 60px;
    margin-bottom: 40px;
    width: 40%;
    border: 1px solid black;
    th {
      border-bottom: 1px solid black;
      font-size: 16px;
      font-weight: 400;
      line-height: 25px;
      // color: #ffff;
      padding: 15px 0;
      text-align: center;
      width: 100%;
      &:nth-child(even) {
        border-left: 1px solid black;
      }
      &:first-child {
        width: 35%;
        text-align: left;
        padding-left: 10px;
      }
    }

    tr {
      border-bottom: 1px solid black;
      td {
        padding: 20px 10px;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        color: black;
        &:nth-child(even) {
          border-left: 1px solid black;
          text-align: center;
        }
      }
    }
  }
}

.corporativeTable {
  display: flex;
  align-items: center;
  justify-content: center;
  .table {
    width: 40%;
    border: 1px solid black;
    margin-top: 20px;
    th {
      border-bottom: 1px solid black;
      font-size: 16px;
      font-weight: 400;
      line-height: 25px;
      // color: #ffff;
      padding: 15px 0;
      text-align: center;
      width: 100%;
      &:nth-child(even) {
        border-left: 1px solid black;
      }
      &:first-child {
        width: 45%;
        text-align: left;
        padding-left: 10px;
      }
    }

    tr {
      border-bottom: 1px solid black;
      td {
        padding: 20px 10px;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        color: black;
        &:nth-child(even) {
          border-left: 1px solid black;
          text-align: center;
        }
      }
    }
  }
}
.sortbutton {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}
.ant-menu-submenu-title {
  &:hover {
    color: #1890ff !important;
  }
}

.ant-avatar.ant-avatar-icon {
  background-color: #1890ff;
}

.close-btn {
  cursor: pointer;
  font-size: 23px;
  color: #1890ff;
}

.tableindex {
  width: 7%;
}

.register-head {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  .ant-select-multiple .ant-select-selector {
    border: none;
  }
  .labelPlace {
    position: absolute;
    top: 52%;
    // left: -35%;
    right: 35%;
    z-index: 1;
    color: #1890ff;
    font-size: 26px;
    pointer-events: none;
    transform: translate(50%, -50%);
  }
  .file-icon {
    border: none;
    padding-top: 0;
    img {
      width: 35px;
      height: 30px;
    }
  }
}

.colm {
  display: flex;
  flex-direction: column;
  row-gap: 10;
}

@media (max-width: 1400px) {
  .register-head .labelPlace {
    right: 70%;
  }
}

@media (max-width: 700px) {
  .register-head .labelPlace {
    top: 36%;
    right: 52%;
  }
}

.chartjs-render-monitor {
  width: 50% !important;
  height: 500px !important;
}

.dashboard {
  .company {
    text-align: center;
    min-height: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 60px;
    margin-top: 30px;
    width: 40%;
    padding: 10px;
    background-color: #fff;
    p {
      font-size: 16px;
    }
    span {
      font-size: 16px;
    }
  }
  .title {
    font-size: 16px;
    margin-bottom: 20px !important;
  }
}

.ant-spin-dot {
  font-size: xx-large;
}

.container {
  width: 300px;
  margin: auto;
}

// .basic-info {
//   transition: height 0.3s ease; /* Geçiş efekti için */
// }

.additional-info {
  height: 0; /* Başlangıçta ek bilgiler gizli */
  overflow: hidden; /* Taşan içeriği gizle */
  transition: height 0.9s ease; /* Geçiş efekti için */
}

.show-additional {
  height: auto; /* Yüksekliği otomatik olarak ayarla, içeriğe göre genişleyecek */
}

.detail-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid rgb(232, 232, 232);
  padding: 20px 0 20px 30px;
  margin-top: 30px;
  background-color: white;
  min-height: 372px;
}
.detail-title {
  font-size: 20px;
  margin-bottom: 40px !important;
}
.detail-left {
  font-size: 16px;
}

.detail-right {
  font-size: 14px;
  width: 30%;
}
.down {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 20px;
  margin-top: 20px;
}
.gap-8{
  gap: 24px;
}

.contact-detail {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;

  .contact {
    width: 100%;
    border: 1px solid rgb(232, 232, 232);
    background-color: #fff;
    padding: 20px 20px 30px 30px;
    display: flex;
    flex-direction: column;
    min-height: 176px;

    .icons {
      display: flex;
     
      .icon {
        font-size: 25px;
        cursor: pointer;
        width: 25%;
        display: grid;
        place-items: center;
        padding: 15px 0;
      }

      .iconCeo {
        font-size: 25px;
        cursor: pointer;
        width: 34%;
        display: grid;
        place-items: center;
        padding: 15px 0;
      }
      .deaktiv {
        border-bottom: 1px solid #000;
      }
      .aktiv {
       border: 1px solid #000;
       border-bottom: none; 
      }

      
    }
    .info {
      width: 100%;
      padding: 30px;
      //border: 1px solid black;
      border-top: none;
      text-align: center;
      border: 1px solid #000;
      border-top: none;
      
    }
  }
}



// @media (min-width: 1900px) {
//  .contact-detail{
//   .contact{
//     min-height: 167px;
//   }
//  }
// }

.ant-tabs-nav-list{
  padding-left: 20px;
}

.dh{

  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}