.admin-login-box {
  width: 400px;
  padding: 20px 30px;
  .admin-login-row {
    input {
      width: 100%;
      height: 45px;
      padding: 0 10px;
      background-color: #ebe6e2;
      color: #22453e;
      font-weight: bold;
      margin: 15px 0 5px 0;
      outline: 0;
      &:focus {
        box-shadow: 0 0 2px 0 rgba(50, 120, 103, 1);
      }
    }
    input[type="submit"] {
      background-color: #51bfa4;
      color: white;
      cursor: pointer;
      &:hover {
        background-color: #327867;
      }
    }
  }
}

.example {
  margin: 12px;
}

/* Force update ant style */
.ant-input {
  height: 40px !important;
  margin-top: 10px !important;
}

.login-page input,
.login-page .ant-input {
  border: none;
}


.ant-select .ant-select-selector {
  padding: 16px 10px 4px 11px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 16px 10px 4px 11px;
  height: 48px;
}

.ant-select-single .ant-select-selector .ant-select-selection-search {
  top: 16px;
}

.float-label {
  position: relative;
  margin-bottom: 12px;
}

.label {
  font-size: 12px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 12px;
  top: 22px;
  transition: 0.2s ease all;
  z-index: 10 !important;
}

.label-float {
  top: -8px;
  left: 0px;
  font-weight: bold;
  font-size: 10px;
  padding: 1px 2px;
}


.login{
  min-height:70vh; 


}
.login-card{
  width: 35%;
  border-radius: 13px;
  padding: 50px 80px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .submit{
    button{
      background-color: #3B82F6;
      color: #fff;
      width: 100%;
      padding: 0.75rem 1.25rem;
      border-radius: 6px;
      height: 46px;
      font-size: 16px;
      font-weight: 600;
      display: flex
;
    align-items: center;
    justify-content: center;
    }
  }
  .mb-4{
    margin-bottom: 40px;
  }
  img{
    height: 100px !important;
  }
  .error{
    color: red;
    font-size: 14px;
  }
  .title{
    font-size: 15px;
    width: 60%;
    font-weight: 700;
  }

  .login-name{
    color: #135497;
    margin-bottom: 15px;
    width: 25%;
  }
  .submit{
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
    input{
      width: 100%;
      padding: 15px 0;
      background-color: #135497;
      border-radius: 4px;
      border: none;
      color: #fff;
    }
   
  }
  .choose{
      margin-top: 10px;
      margin-bottom: 28px;
        justify-content: space-between;
        display: flex;
  }
  .up{
    width: 100%;
    position: relative;
      label{
        position: absolute;
        font-size: 14px;
        color: #000;
        font-weight: 300;
       padding: 3px 10px;
        display: grid;
        place-items: center;
        left: 15px;
        top: -13px;
        background-color: #fff;
        z-index: 1;
       }
    
   
    .eye{
      position: absolute;
      top: 12px;
      font-size: 20px;
      right: 17px;
    }
    .ant-input-password{
      width: 100%;
      border-radius: 6px;
      border: 1px solid #ced4da;
    }
    input{
      width: 100%;
      height: 44px!important;
      border-radius: 6px;
      border: 1px solid #ced4da;
    }
  
  }
}

.footer{
  padding: 24px 10px;
  width: 100%;
 
}
.main-footer{
  position: sticky!important;
  top: 100%!important;
  width: 100%;
  .gap-4{
    gap: 24px;
  }
  a{
    color: #fff;
  }
}

.icon{
       width: 16px;
       height: 15px;
       font-size: 12px;
       background-color: #fff;
       display: grid;
       place-items: center;
       margin-top: 1px;
       border-radius: 2px;
        svg{
            color: #075da8;
        }  
}

@media screen and (max-width:768px){
  .footer{
    padding: 10px 35px;
    flex-direction: column;
    gap:15px;
  
    .footer-text{
      font-size: 15px !important;
    }
    .social-medias{
      gap: 20px !important;
      svg{
        font-size: 19px;
      }
    }
  }
  .icon {
    width: 19px;
    height: 19px;
    border-radius: 3px;
}
}